import React, { useEffect, useState } from 'react';

import { setToken } from '@/redux/reducers/Wallet.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/redux/connectors.redux';
import { useLanguages } from '@/hooks/useLanguages.hook';

import cx from 'classnames';
import css from './myPageGlobal.module.scss';

import OnSale from '@/pages/NFTReturn/components/OnSale';
import KlayMint from '@/helpers/KlayMint';
import Listed from '@/pages/NFTReturn/components/Listed';

const NFTReturn = () => {
    const { list } = useSelector((store: RootState) => store.Collections);
    const dispatch = useDispatch();
    const klaymint = new KlayMint('', '', list);

    const wallet = useSelector((store: RootState) => store.Wallet);
    const Lang = useLanguages();

    const [currentContent, setCurrentContent] = useState('UNLISTED');

    const [loading, setLoading] = useState<boolean>(false);

    const loadTokensHandler = async (list) => {
        if (wallet?.type !== 'none' && list.length !== 0) {
            setLoading(true);

            klaymint
                .getToken(wallet, list)
                .then((res) => {
                    dispatch(
                        setToken({
                            ...wallet.info.myToken,
                            unlisted: res.unlisted,
                            onSale: res.onSale,
                        }),
                    );
                    setLoading(false);
                })
                .catch((err) => {
                    dispatch(
                        setToken({
                            ...wallet.info.myToken,
                            unlisted: [],
                            onSale: [],
                        }),
                    );
                    window.toast('error', Lang.err_msg_fail_request);
                    console.error('load token error :: ', err);
                    setLoading(false);
                });
        }
    };

    useEffect(() => {
        if (list.length) loadTokensHandler(list);
    }, [wallet.isConn, wallet?.type, wallet.info.address, list, currentContent]);

    return (
        <>
            {/* <main className={cx('mt-5 pt-5', css.myPageSection)} /> */}
            <Listed loading={loading} />
            {/* {currentContent === 'ON SALE' && <OnSale loading={loading} />} */}
            {/* {modal.onSale || modal.unlisted ? (
                <div className="backgroundShadow" onClick={() => setModal({ onSale: false, unlisted: false })} />
            ) : null} */}
        </>
    );
};

export default NFTReturn;
