import React, { useEffect, useRef, useState } from 'react';
import css from '@/pages/CollectionsDetail/components/Article.module.scss';
import cx from 'classnames';
import _ from 'lodash';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/redux/connectors.redux';
import { ArticleModalProps } from '@/_components/commons/modals/_ArticleModal.interfaces.declare';

import useList from '@/hooks/useList.hook';
import { useLanguages } from '@/hooks/useLanguages.hook';

import MyPageModal from '@/pages/NFTReturn/components/Modal';
import NoItems from '@/_components/commons/NoItems';
import KlayMint from '@/helpers/KlayMint';
import S from '@/_statics/images/PerPlus/s.svg';
import { completeTransfer } from '@/helpers/klaymint.api';
import { getENMySalesList, getIsOwner } from '@/helpers/klaymint.api';
import Caver, { AbiItem } from 'caver-js';

import MockData from '../mockNFT.json';
import axios from 'axios';
import KlayMintUtil from '@/helpers/KlayMint.util';

const ennode = 'https://en.ciccommunity.com:8551';
const contractAddress = '0x98649369e4ca48ea41351bbc75a562bc9eea7662';
const perFriendsAbi: AbiItem[] = [
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '_tokenId',
                type: 'uint256',
            },
        ],
        name: 'tokenURI',
        outputs: [
            {
                internalType: 'string',
                name: 'uri',
                type: 'string',
            },
        ],
        stateMutability: 'nonpayable',
        type: 'function',
    },
];
const perFriendsAddress = '0x0ed55aee0399064cfe51dd3cc10d99734bb796c7';
const contractAbi: AbiItem[] = [
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '_tokenId',
                type: 'uint256',
            },
        ],
        name: 'sell_cancel',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_user',
                type: 'address',
            },
        ],
        name: 'user_selling_items',
        outputs: [
            {
                internalType: 'uint256[]',
                name: '',
                type: 'uint256[]',
            },
            {
                internalType: 'uint256[]',
                name: '',
                type: 'uint256[]',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
];

const caver = new Caver(ennode);
const contract = new caver.klay.Contract(contractAbi, contractAddress);
const penContract = new caver.klay.Contract(perFriendsAbi, perFriendsAddress);

console.log(contract, penContract);

const Listed = ({ loading }) => {
    const dispatch = useDispatch();
    const wallet = useSelector((store: RootState) => store.Wallet);
    const { list, classs } = useSelector((store: RootState) => store.Collections);
    const { mobile, language } = useSelector((store: RootState) => store.GlobalStatus);

    const Lang = useLanguages();

    const [modal, setModal] = useState(false);
    const [modalProps, setModalProps] = useState({} as ArticleModalProps);
    const [onSaleList, setOnSaleList] = useState<Record<string, any>[]>([]);
    const [isReturning, setIsReturning] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const klaymint = new KlayMint(contractAddress, contractAddress, list);
    const klaymintUtil = new KlayMintUtil();

    const {
        state,
        onRefresh,
        onAppend,

        pages,
        movePage,

        scrollEventListener,
    } = useList({});

    // const modalOnClickHandler = async (item) => {
    //     const currentContract = list.filter(
    //         (obj) => obj.contract_address.toLowerCase() === item.data.contractAddress.toLowerCase(),
    //     );

    //     const currentClasss = classs[currentContract[0]?.id];

    //     // 나중에 같은 컨트랙트에서 두개의 팩토리가 나오는 경우에는 로직을 수정해야하는 부분
    //     // 인덱스가 0이 아니라 별도의 방법을 통해 두개의 팩토리중 해당 팩토리를 찾아야함
    //     const klaymint = new KlayMint(currentContract[0]?.contract_address, currentContract[0]?.factory_address, list);

    //     const isWallet = wallet.info.address !== '';
    //     const attrArr = item.data.attributes
    //         ?.filter((value) => value.trait_type !== 'id')
    //         .map((value) => {
    //             if (value.trait_type === 'CLASS') {
    //                 try {
    //                     item.data.class = JSON.parse(value.value)[1];
    //                 } catch (error) {
    //                     item.data.class = value.value;
    //                 }
    //             }

    //             return Object.values(value);
    //         });

    //     setModalProps({
    //         collection: currentContract[0],
    //         props: {
    //             title: `${item.data.name}`,
    //             mainImage: `${item.data.image}`,
    //             mainVideo: item.data.video ?? null,
    //             mainDesc: item.data.description,
    //             mainAttrs: attrArr,
    //             mainAttrDesc: currentClasss,
    //             footerInput: true,
    //             footerButtons: isWallet ? 'SELL' : 'CONNECT WALLET',
    //             onClick: {
    //                 event: isWallet
    //                     ? klaymint.sellRequest
    //                     : () => window?.toast('error', Lang.err_msg_fail_connect_wallet),
    //                 argument: [item.data.token_id, dispatch],
    //             },
    //             item: item,
    //         },
    //     });
    //     setModal(true);
    // };

    // const transferHandler = (item) => {
    //     setModalProps({
    //         collection: { is_class: true },
    //         props: {
    //             jsxContent: <TransferModal token={item} setModal={setModal} />,
    //         },
    //         style: {
    //             width: 'auto',
    //         },
    //     });
    //     setModal(true);
    // };

    useEffect(
        () =>
            scrollEventListener(
                document,
                () => {
                    console.log('on [unlistied] Refresh');
                },
                () => {
                    if (loading) return;
                    console.log('on [unlistied] load append');
                    //onRefresh(wallet.info.myToken.unlisted);
                    /** test code */
                    //__TEST__fakeAsyncFunciton();
                    //asyncFinxtion();
                },
            ),
        [],
    );

    useEffect(() => {
        onRefresh(wallet.info.myToken.unlisted);
    }, [wallet.info.myToken.unlisted]);

    useEffect(() => {
        modal ? (document.body.style.overflowY = 'hidden') : (document.body.style.overflowY = 'unset');
    }, [modal]);

    const ownerOf = async (tokenId, type, contractAddress) => {
        if (type === 'kaikas') {
            const { klaytn }: any = window;
            if (klaytn) {
                const caver = new Caver(klaytn);

                const kip17 = caver.kct.kip17.create(contractAddress);
                const res = await kip17.ownerOf(tokenId);

                return res;
            }
        } else {
            const res = await getIsOwner(tokenId, contractAddress);

            return res.data;
        }
    };

    const getSellingItems = async (_address) => {
        try {
            const response = await contract.methods.user_selling_items(_address).call();

            const itemIds = response[0];

            const saleList = await Promise.all(
                itemIds.map(async (id) => {
                    const data = await getTokenUri(id);
                    return { id, data };
                }),
            );
            setOnSaleList(saleList);
        } catch (e) {
            console.log('판매중 아이템 호출 오류 :', e);
        }
    };

    const getTokenUri = async (_id) => {
        try {
            const uri = await penContract.methods.tokenURI(_id).call();
            const tokenJsonURI = uri.replace('https://ipfs.io/ipfs/', 'https://klaymint.mypinata.cloud/ipfs/');
            const tokenInfo = await axios.get(tokenJsonURI);
            return tokenInfo?.data;
        } catch (e) {
            console.log('토큰 이미지 호출 오류', e);
        }
    };

    const returnHandler = async (item) => {
        setIsReturning(true);
        /* TODO -- await NFT PER 로 반환 요청하는 로직 */
        try {
            await klaymint.sellCancelRequest(
                { wallet: wallet, tokenId: item?.id },
                {
                    sucCallback(receipt?) {
                        klaymint.updateFactoryList();
                        window.toast('success', Lang.suc_msg_sucs_sellCancel);
                        console.log('345');
                        setIsReturning(false);
                    },
                    failCallback() {
                        console.log('123');
                        window.toast('error', Lang.err_msg_sucs_sellCancel);
                        setIsReturning(false);
                        setError(true);
                    },
                    exceptionCallback() {
                        setIsReturning(false);
                        setError(true);
                    },
                },
                { dispatch: dispatch, Lang: Lang },
            );
        } catch (error) {
            console.log(error);
            setIsReturning(false);
            setError(true);
            window.toast('error', Lang.err_msg_sucs_sellCancel);
        }
    };

    useEffect(() => {
        if (wallet?.info?.address?.length <= 0) {
            setOnSaleList([]);
            return;
        }
        if (wallet?.info?.address) {
            getSellingItems(wallet.info.address);
        }
    }, [wallet?.info?.address]);

    if (loading) {
        return (
            <div className={cx(css.returningSpinner, 'mt-5 pt-5 d-flex justify-content-center')}>
                <div className="spinner-border-xl" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                <span>{language === 'ko-KR' ? '목록을 불러오고 있습니다...' : 'Loading List...'}</span>
            </div>
        );
    }

    return (
        <div className="mt-5 pt-5 relative">
            {isReturning && (
                <div className={css.returningSpinner}>
                    <div className="spinner-border-xl" role="status">
                        <span className="visually-hidden">Returning...</span>
                    </div>
                    <span>{language === 'ko-KR' ? '반환 진행중입니다...' : 'Returning in Progress'}</span>
                </div>
            )}
            <section className={cx('row mt-5 pt-5', css.ArticleSection, css.myPageArticleList)}>
                {/* {_.keys(state.view_list).length <= 0 && <NoItems>{Lang.list_no_my_items}</NoItems>} */}
                {_.keys(onSaleList).length <= 0 && <NoItems>{Lang.list_no_my_items}</NoItems>}
                {_.map(onSaleList, (item) => {
                    return (
                        <div key={item?.id} className={cx('col-md-2', css.articleContainer, css.unlistedArticle)}>
                            <div className={css.card}>
                                <div className={css.imgContainer}>
                                    {item?.data?.video ? (
                                        <video
                                            className="articleVideos"
                                            controls
                                            controlsList="nodownload"
                                            autoPlay
                                            muted
                                            loop
                                            playsInline
                                            poster={item?.data?.image}
                                        >
                                            <source src={item?.data?.video} type="video/mp4" />
                                        </video>
                                    ) : (
                                        <img src={item?.data?.image} alt="nft" />
                                    )}
                                    {item?.data?.isStaking ? (
                                        <img src={S} alt="S" className={css.StakingcardMark} />
                                    ) : null}
                                </div>
                                <div className={css.txtContainer}>
                                    <span>{item?.data?.name ?? `# ${item?.id}`}</span>
                                    <div className={css.buttonDiv}>
                                        {/* <button type="button" onClick={() => modalOnClickHandler(item)}>
                                            <span>{Lang.sell_button}</span>
                                        </button> */}
                                        <button
                                            type="button"
                                            onClick={(e) => {
                                                returnHandler(item);
                                                e.stopPropagation();
                                            }}
                                            disabled={isReturning}
                                        >
                                            <span>RETURN</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </section>
            {/* {modal ? <MyPageModal setModal={setModal} modalProps={modalProps} /> : null} */}
        </div>
    );
};

// const TransferModal = ({ token, setModal }) => {
//     const wallet = useSelector((store: RootState) => store.Wallet);
//     const { list } = useSelector((store: RootState) => store.Collections);
//     const Lang = useLanguages();
//     const dispatch = useDispatch();

//     const [toAddress, setToAddress] = useState('');

//     const addressInputRef = useRef(null);
//     const validateCheckRef = useRef(null);

//     const addressOnChangeHandler = (e) => {
//         if (e.target.value.length === 0 && addressInputRef.current !== null) {
//             validateCheckRef.current.style.display = 'none';
//             addressInputRef.current.style.border = '1px solid';
//         } else if (
//             e.target.value.length !== 42 &&
//             validateCheckRef.current !== null &&
//             addressInputRef.current !== null
//         ) {
//             validateCheckRef.current.style.display = 'block';
//             addressInputRef.current.style.border = '1px solid rgb(249 81 81 / 80%)';
//             addressInputRef.current.style.outline = 'none';
//         } else if (
//             e.target.value.length === 42 &&
//             validateCheckRef.current !== null &&
//             addressInputRef.current !== null
//         ) {
//             validateCheckRef.current.style.display = 'none';
//             addressInputRef.current.style.border = '1px solid';
//         }

//         setToAddress(e.target.value);
//     };

//     const transferHandler = async () => {
//         const currentContract = list.filter(
//             (obj) => obj.contract_address.toLowerCase() === token.data.contractAddress.toLowerCase(),
//         );

//         // 나중에 같은 컨트랙트에서 두개의 팩토리가 나오는 경우에는 로직을 수정해야하는 부분
//         // 인덱스가 0이 아니라 별도의 방법을 통해 두개의 팩토리중 해당 팩토리를 찾아야함
//         const klaymint = new KlayMint(
//             currentContract[0]?.contract_address,
//             currentContract[0]?.factory_address,
//             list,
//             currentContract[0].id,
//         );

//         const isAddress = await klaymint.isAddress(toAddress);
//         if (!isAddress) return window.toast('error', Lang.err_msg_transfer_invalid_address);

//         klaymint.transfer(
//             { wallet: wallet, toAddress: toAddress, tokenId: token.data.token_id },
//             {
//                 sucCallback(receipt?) {
//                     setModal(false);
//                     window.toast('success', Lang.suc_msg_transfer);
//                 },
//                 failCallback() {
//                     setModal(false);
//                     window.toast('error', Lang.err_msg_transfer);
//                 },
//                 exceptionCallback() {
//                     setModal(false);
//                 },
//                 saveTxCallback(txHash) {
//                     completeTransfer(toAddress, wallet.info.address, this.ctl_idx, token.data.token_id, txHash);
//                 },
//             },
//             { dispatch: dispatch, Lang: Lang },
//         );
//     };

//     return (
//         <section className={css.transferSection}>
//             <div className={css.transferTitleContainer}>
//                 <h3>{Lang.title_transfer}</h3>
//             </div>
//             <div className={css.transferImageContainer}>
//                 <div className={css.transferMainImage}>
//                     <img src={token.data.image} alt="tImage" />
//                     {token.data.isStaking ? <img src={S} alt="S" className={css.StakingcardMark} /> : null}
//                 </div>
//                 <h5>{token.data.name}</h5>
//             </div>
//             <div className={css.transferFromContainer}>
//                 <p>{Lang.from_transfer}</p>
//                 <input defaultValue={wallet?.info?.address} readOnly />
//             </div>
//             <div className={css.transferToContainer}>
//                 <p>{Lang.to_transfer}</p>
//                 <input
//                     type="text"
//                     onChange={addressOnChangeHandler}
//                     placeholder={Lang.placeholder_msg_transfer}
//                     ref={addressInputRef}
//                 />
//                 <p ref={validateCheckRef} className={css.transferValidate}>
//                     <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         aria-hidden="true"
//                         role="img"
//                         width="1em"
//                         height="1em"
//                         preserveAspectRatio="xMidYMid meet"
//                         viewBox="0 0 24 24"
//                     >
//                         <path d="M13 9h-2V7h2m0 10h-2v-6h2m-1-9A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2z" />
//                     </svg>
//                     {Lang.err_msg_transfer_invalid_address}
//                 </p>
//             </div>
//             <div className={css.transferButtonContainer}>
//                 <p>{Lang.warning_msg_transfer}</p>
//                 <button type="button" onClick={transferHandler}>
//                     {Lang.transfer_button}
//                 </button>
//             </div>
//         </section>
//     );
// };

export default React.memo(Listed);
