import React, { useCallback, useState } from 'react';
import _ from 'lodash';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/redux/connectors.redux';

import KlayMint from '@/helpers/KlayMint';
import { useLanguages } from '@/hooks/useLanguages.hook';

import './main.css';
import css from './main.module.scss';

import bg00 from '@/_statics/images/minigame.jpg';

// import Next12 from '@/_statics/images/main_next_series/Next1_2.png';
// import Next13 from '@/_statics/images/main_next_series/Next1_3.png';
// import Next14 from '@/_statics/images/main_next_series/Next1_4.png';
// import Next5 from '@/_statics/images/main_next_series/Next4.png';
import Next4 from '@/_statics/images/main_next_series/Next5.png';

import klayicon from '@/_statics/images/icon_kaikas.svg';

import { remoteWizardOpener, Wizard } from '@/_components/commons/wizard';
import { remoteModalOpener } from '@/_components/commons/modals';

import { Slide } from 'react-reveal';
import ImageSlider from '../_layouts/components/ImageSlider';
import Section from './components/Section';
import Box from './components/Box';
import MintArea from './components/MintArea';
import { setToken } from '@/redux/reducers/Wallet.reducer';
import { MessageEventType, MessageType } from '@/helpers/WebSocket/index.declare';
import useSendMessage from '@/hooks/Websocket/useSendMessage.hook';
import cx from 'classnames';
import OnSale from '../NFTReturn/components/OnSale';
// import MyPage from '../MyPage';
import NFTReturn from '../NFTReturn';

const index = () => {
    const dispatch = useDispatch();
    const Lang = useLanguages();
    const wallet = useSelector((store: RootState) => store.Wallet);
    const { list } = useSelector((store: RootState) => store.Collections);
    const { mobile } = useSelector((store: RootState) => store.GlobalStatus);

    /**
     * Minnting 민팅 시도 시 발생 이밴트
     */

    return (
        <>
            <main className="container">
                {/* <div
                    className={cx(
                        'container-fluid d-flex justify-content-center',
                        mobile ? css.backgroundImg_mobile : css.backgroundImg,
                    )}
                >
                    <div className="align-self-center text-light p-5 m-5">
                        <div className="row col-12">
                            <div
                                className="text-lighter text-center"
                                style={!mobile ? null : { marginLeft: '20px' }}
                            ></div>

                            <div
                                className=""
                                style={
                                    !mobile
                                        ? { fontSize: '1rem', textAlign: 'center' }
                                        : {
                                              fontSize: '0.8rem',
                                              textAlign: 'center',
                                              marginLeft: '20px',
                                              width: '400px',
                                          }
                                }
                            >
                                <br />
                                안녕하세요, KlayMint 입니다.
                                <br />
                                우리 샤따 내립니다.
                                <br />
                                당신들의 NFT PER 로 바꿔가세요.
                                <br />
                                <br />
                                그럼 쎄굿바.
                            </div>
                        </div>
                    </div>
                </div> */}
                <NFTReturn />
            </main>
        </>
    );
};

export default React.memo(index);
