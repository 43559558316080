import {
    buyNFTABI,
    sellCancelNFTABI,
    sellNFTABI,
    setApprovalForAllABI,
    safeTransferFromABI,
    multiMint_ABI,
    setApprovoalForKip37ABI,
    isPerApprovedABI,
} from '@/includes/abi';
import { prepare } from 'klip-sdk';
import { enqueueSalesList, getENSalesList } from '@/helpers/klaymint.api';
import { WALLETS } from '@/redux/reducers/Wallet.reducer';
import KlipHelper from '@/helpers/Wallet/klip.helper';
import KaikasHelper from '@/helpers/Wallet/kaikas.helper';
import { Dispatch } from 'redux';
import KlayMintUtil from '@/helpers/KlayMint.util';
import { myBAppName, qNumber } from '@/includes/envVariables';

/**
 * class 컴포넌트를 위해 필요한 Input interface 정의
 */
export interface KlayMintParamsInput {
    wallet: WALLETS;

    /**
     * mint를 위한 prams
     */
    mtl_idx?: number;
    mtl_price?: number;
    amount?: number;

    // sell, sellCancel, buy, transfer
    /**
     * sell, sellCancel, buy, transfer를 위한 params
     */
    tokenId?: number;
    sellPrice?: number;
    tokenInfo?: any;
    toAddress?: string;

    /**
     * approve를 위한 params
     */
    spender?: string;
    operator?: string;
}

/**
 * 함수 실행 후 동작할 interface 정의
 */
export interface KlayMintParamsOutput {
    sucCallback(receipt?): void;
    failCallback(error?): void;
    exceptionCallback?(error?): void;
    saveTxCallback?(txHash): void;
}

/**
 * 함수 실행시 필요한 utility interface 정의의 */
export interface KlayMintParamsUtil {
    dispatch?: Dispatch;
    Lang?: Record<string, any>;
}

/**
 * contractAddress 와 factoryAddress 를 받아서 블록체인과 통신 가능한 인스턴스 객체를 생성할 수 있는 인스턴스
 */
export default class KlayMint extends KlayMintUtil {
    // for extends
    public contractAddress: string;
    protected factoryAddress: string;
    protected list: any[];
    protected ctl_idx: number;

    // util
    protected klipHelper: KlipHelper;
    protected kaikasHelper: KaikasHelper;

    // getToken 메서드를 사용하기 위해서 접근하는 인스턴스 객체는 contractAddress 와 factory
    constructor(contractAddress: string, factoryAddress: string, list: any[], ctl_idx?: number) {
        super();

        this.contractAddress = contractAddress;
        this.factoryAddress = factoryAddress;
        this.list = list;
        this.ctl_idx = ctl_idx;

        this.klipHelper = new KlipHelper();
        this.kaikasHelper = new KaikasHelper(window.klaytn);
    }

    public updateFactoryList = () => {
        enqueueSalesList(this.contractAddress.toLowerCase())
            .then(() => console.log('update'))
            .catch((error) => console.log(error));
    };
    /**
     * mint를 진행하기위한 request / wallet연결이 안되있으면 return
     * peb을 klay로 변환하고 peb의 type이 string일 경우 km.util함수인 endFunc
     * @param input redux의 wallet
     * @param output
     * @param util
     */
    public mintRequest = async (input: KlayMintParamsInput, output: KlayMintParamsOutput, util: KlayMintParamsUtil) => {
        const res = await this.walletStatusCheck(input.wallet, output.exceptionCallback, util.Lang);
        if (!res) return;

        const peb = await this.toPebFromKlay(input.mtl_price * input.amount);
        if (typeof peb !== 'string') return this.endFunction(output.exceptionCallback, util.Lang.err_msg_sucs_mint);

        if (input.wallet.type === 'klip') {
            const prepareSign = await prepare.executeContract({
                bappName: myBAppName,
                from: input.wallet.info.address,
                to: this.factoryAddress,
                value: peb,
                abi: JSON.stringify(multiMint_ABI),
                params: JSON.stringify([input.mtl_idx, +qNumber, input.amount]),
                successLink: '#',
                failLink: '#',
            });
            prepareSign.type = 'mint';

            await this.klipHelper.sendTransaction(prepareSign, output, util);
        } else if (input.wallet.type === 'kaikas') {
            const abi = this.caver.klay.abi.encodeFunctionCall(multiMint_ABI, [input.mtl_idx, +qNumber, input.amount]);

            await this.kaikasHelper.sendTransaction(
                { toAddress: this.factoryAddress, fromAddress: input.wallet.info.address, abi, value: peb },
                output,
            );
        }
    };
    /**
     * NFT 판매를 위한 request
     * 1step : 월렛의 지갑상태 체크 => 없으면 return
     * 2step : 토큰에 대한 owner 체크
     * 3step : klip, kaikas 에 따라 transaction 실행
     * @param input
     * @param output
     * @param util
     */
    public sellRequest = async (input: KlayMintParamsInput, output: KlayMintParamsOutput, util: KlayMintParamsUtil) => {
        const res = await this.walletStatusCheck(input.wallet, output.exceptionCallback, util.Lang);
        if (!res) return;

        const isOwner = await this.isOwner(input.tokenId, this.contractAddress);
        if (isOwner.toLowerCase() !== input.wallet.info.address.toLowerCase())
            return this.endFunction(output.exceptionCallback, util.Lang.err_msg_fail_not_token_owner);

        if (!input.sellPrice) return this.endFunction(output.exceptionCallback, util.Lang.err_msg_fail_price_empty);
        if (!Number.isInteger(input.sellPrice)) {
            const cnt = (input.sellPrice + '').split('.')[1].length;
            if (cnt > 2) return this.endFunction(output.exceptionCallback, util.Lang.err_msg_fail_price_not_integers);
        }
        if (input.sellPrice > 99999999.99)
            return this.endFunction(output.exceptionCallback, util.Lang.err_msg_fail_price_too_big);

        const toPeb = await this.toPebFromKlay(input.sellPrice);
        if (typeof toPeb !== 'string')
            return this.endFunction(output.exceptionCallback, util.Lang.err_msg_fail_price_not_integers);

        if (input.wallet.type === 'klip') {
            const prepareSign = await prepare.executeContract({
                bappName: myBAppName,
                from: input.wallet.info.address,
                to: this.factoryAddress,
                abi: JSON.stringify(sellNFTABI),
                params: JSON.stringify([input.tokenId, toPeb]),
                value: '0',
                successLink: '#',
                failLink: '#',
            });
            prepareSign.type = 'sell';

            await this.klipHelper.sendTransaction(prepareSign, output, util);
        } else {
            const abi = this.caver.klay.abi.encodeFunctionCall(sellNFTABI, [input.tokenId, toPeb]);

            await this.kaikasHelper.sendTransaction(
                {
                    toAddress: this.factoryAddress,
                    fromAddress: input.wallet.info.address,
                    abi: abi,
                },
                output,
            );
        }
    };
    /**
     * NFT 판매신청을 취소하는 request
     * 1step : wallet 상태 체크
     * 2step : tokenOwnerCheckInFactory를 이용해 factory에서 token owner를 조회
     * 3step : transaction 실행
     * @param input
     * @param output
     * @param util
     */
    public sellCancelRequest = async (
        input: KlayMintParamsInput,
        output: KlayMintParamsOutput,
        util: KlayMintParamsUtil,
    ) => {
        const walletValidate = await this.walletStatusCheck(input.wallet, output.exceptionCallback, util.Lang);
        const salesTokenValidate = await this.tokenOwnerCheckInFactory(
            {
                wallet: input.wallet,
                tokenId: input.tokenId,
                contractAddress: this.contractAddress,
                factoryAddress: this.factoryAddress,
            },
            { exceptionCallback: output.exceptionCallback },
            { Lang: util.Lang },
        );

        console.log('집에 가고싶다 ㅅㅂ',walletValidate, salesTokenValidate)
        if (typeof salesTokenValidate === 'string') this.factoryAddress = salesTokenValidate;
        if (typeof salesTokenValidate === 'boolean' && !walletValidate && !salesTokenValidate) return;

        if (input.wallet.type === 'klip') {
            const prepareSign = await prepare.executeContract({
                bappName: myBAppName,
                from: input.wallet.info.address,
                to: this.factoryAddress,
                value: '0',
                abi: JSON.stringify(sellCancelNFTABI),
                params: JSON.stringify([input.tokenId]),
                successLink: '#',
                failLink: '#',
            });
            prepareSign.type = 'sellCancel';

            await this.klipHelper.sendTransaction(prepareSign, output, util);
        } else {
            const abi = this.caver.klay.abi.encodeFunctionCall(sellCancelNFTABI, [input.tokenId]);

            await this.kaikasHelper.sendTransaction(
                { toAddress: this.factoryAddress, fromAddress: input.wallet.info.address, abi: abi },
                output,
            );
        }
    };
    /**
     * NFT구매를 위한 request
     * 1step : 지갑연결 상태 조히
     * 2step : tokenOwnerCheckInFactory 를 이용해 토큰의 owner 조회
     * salesTokenValidate가 string으로 반환하면 구 팩토리주소를 새 팩토리주소로 변환
     * slaesRokenValidate가 boolean && 월렛상태가false && salesTokenValidate가 false라면 return
     * 3step : transaction 실행행     * @param input
     * @param output
     * @param util
     */
    public buyRequest = async (input: KlayMintParamsInput, output: KlayMintParamsOutput, util: KlayMintParamsUtil) => {
        const walletValidate = await this.walletStatusCheck(input.wallet, output.exceptionCallback, util.Lang);
        const salesTokenValidate = await this.tokenOwnerCheckInFactory(
            {
                wallet: input.wallet,
                tokenId: input.tokenId,
                contractAddress: this.contractAddress,
                factoryAddress: this.factoryAddress,
            },
            { exceptionCallback: output.exceptionCallback },
            { Lang: util.Lang },
        );

        if (typeof salesTokenValidate === 'string') this.factoryAddress = salesTokenValidate;
        if (typeof salesTokenValidate === 'boolean' && !walletValidate && !salesTokenValidate) return;

        const toPeb = await this.toPebFromKlay(input.sellPrice);
        if (typeof toPeb !== 'string') return this.endFunction(output.exceptionCallback, util.Lang.err_msg_sucs_buy);

        if (input.wallet.type === 'klip') {
            const prepareSign = await prepare.executeContract({
                bappName: myBAppName,
                from: input.wallet.info.address,
                to: this.factoryAddress,
                value: toPeb,
                abi: JSON.stringify(buyNFTABI),
                params: JSON.stringify([input.tokenId]),
                successLink: '#',
                failLink: '#',
            });
            prepareSign.type = 'buy';

            await this.klipHelper.sendTransaction(prepareSign, output, util);
        } else {
            const abi = this.caver.klay.abi.encodeFunctionCall(buyNFTABI, [input.tokenId]);

            await this.kaikasHelper.sendTransaction(
                { toAddress: this.factoryAddress, fromAddress: input.wallet.info.address, abi: abi, value: toPeb },
                output,
            );
        }
    };
    /**
     * 해당 컨트랙트에 대한 모든 토큰 Kip17을 모두 apporve하는 func
     * 1step : 지갑상태 조회
     * 2step : transaction 실행
     * @param input
     * @param output
     * @param util
     */
    public setApprovedAll = async (
        input: KlayMintParamsInput,
        output: KlayMintParamsOutput,
        util: KlayMintParamsUtil,
    ) => {
        const res = await this.walletStatusCheck(input.wallet, output.exceptionCallback, util.Lang);
        if (!res) return;

        if (input.wallet.type === 'klip') {
            const prepareSign = await prepare.executeContract({
                bappName: myBAppName,
                from: input.wallet.info.address,
                to: this.contractAddress,
                abi: JSON.stringify(setApprovalForAllABI),
                params: JSON.stringify([input.spender, true]),
                value: '0',
                successLink: '#',
                failLink: '#',
            });
            prepareSign.type = 'kip17Approve';

            await this.klipHelper.sendTransaction(prepareSign, output, util);
        } else {
            const abi = this.caver.klay.abi.encodeFunctionCall(setApprovalForAllABI, [input.spender, true]);

            await this.kaikasHelper.sendTransaction(
                {
                    toAddress: this.contractAddress,
                    fromAddress: input.wallet.info.address,
                    abi: abi,
                },
                output,
            );
        }
    };
    /**
     * kip7에 대해 max값을 approve 해주는 func
     * uint255 -1 = 115792089237316195423570985008687907853269984665640564039157584007913129639935
     * @param input
     * @param output
     * @param util
     */
    public setApprovedMaxKIP7 = async (
        input: KlayMintParamsInput,
        output: KlayMintParamsOutput,
        util: KlayMintParamsUtil,
    ) => {
        const res = await this.walletStatusCheck(input.wallet, output.exceptionCallback, util.Lang);
        if (!res) return;

        if (input.wallet.type === 'klip') {
            const prepareSign = await prepare.executeContract({
                bappName: myBAppName,
                from: input.wallet.info.address,
                to: input.toAddress,
                value: '0',
                abi: JSON.stringify(isPerApprovedABI),
                params: JSON.stringify([
                    input.spender,
                    '115792089237316195423570985008687907853269984665640564039157584007913129639935',
                ]),
                successLink: '#',
                failLink: '#',
            });

            prepareSign.type = 'kip7Approve';
            await this.klipHelper.sendTransaction(prepareSign, output, util);
        }

        if (input.wallet.type === 'kaikas') {
            const abi = this.caver.klay.abi.encodeFunctionCall(isPerApprovedABI, [
                input.spender,
                '115792089237316195423570985008687907853269984665640564039157584007913129639935',
            ]);

            await this.kaikasHelper.sendTransaction(
                {
                    toAddress: input.toAddress,
                    fromAddress: input.wallet.info.address,
                    abi: abi,
                },
                output,
            );
        }
    };
    /**
     * kip37에 대한 apporve func
     * 1step : 지갑 상태 조회
     * 2step : approve transaction 실행
     * @param input
     * @param output
     * @param util
     */
    public setApprovedKip37 = async (
        input: KlayMintParamsInput,
        output: KlayMintParamsOutput,
        util: KlayMintParamsUtil,
    ) => {
        const res = await this.walletStatusCheck(input.wallet, output.exceptionCallback, util.Lang);
        if (!res) return;

        if (input.wallet.type === 'klip') {
            const prepareSign = await prepare.executeContract({
                bappName: myBAppName,
                from: input.wallet.info.address,
                to: input.toAddress,
                abi: JSON.stringify(setApprovoalForKip37ABI),
                params: JSON.stringify([input.spender, true]),
                value: '0',
                successLink: '#',
                failLink: '#',
            });
            prepareSign.type = 'kip37Approve';

            await this.klipHelper.sendTransaction(prepareSign, output, util);
        } else {
            const abi = this.caver.klay.abi.encodeFunctionCall(setApprovoalForKip37ABI, [input.spender, true]);
            await this.kaikasHelper.sendTransaction(
                {
                    toAddress: input.toAddress,
                    fromAddress: input.wallet.info.address,
                    abi: abi,
                },
                output,
            );
        }
    };
    /**
     * NFT transfer를 위한 func
     * 1step : 지갑상태 조회
     * 2step : token의 owner 조회
     * 3step : transfer에 대한 trancsaction 실행
     * @param input
     * @param output
     * @param util
     */
    public transfer = async (input: KlayMintParamsInput, output: KlayMintParamsOutput, util: KlayMintParamsUtil) => {
        const walletValidate = await this.walletStatusCheck(input.wallet, output.exceptionCallback, util.Lang);
        const tokenValidate = await this.tokenOwnerCheck(
            { wallet: input.wallet, tokenId: input.tokenId, contractAddress: this.contractAddress },
            { exceptionCallback: output.exceptionCallback },
            { Lang: util.Lang },
        );

        if (!walletValidate && !tokenValidate) return;

        console.log(input.wallet.info.address, input.toAddress, input.tokenId);
        console.log(this.contractAddress);

        if (input.wallet.type === 'klip') {
            const prepareSign = await prepare.executeContract({
                bappName: myBAppName,
                from: input.wallet.info.address,
                to: this.contractAddress,
                value: '0',
                abi: JSON.stringify(safeTransferFromABI),
                params: JSON.stringify([input.wallet.info.address, input.toAddress, input.tokenId]),
                successLink: '#',
                failLink: '#',
            });
            prepareSign.type = 'transfer';

            await this.klipHelper.sendTransaction(prepareSign, output, util);
        } else {
            const abi = this.caver.klay.abi.encodeFunctionCall(safeTransferFromABI, [
                input.wallet.info.address,
                input.toAddress,
                input.tokenId,
            ]);
            await this.kaikasHelper.sendTransaction(
                {
                    fromAddress: input.wallet.info.address,
                    toAddress: this.contractAddress,
                    abi: abi,
                },
                output,
            );
        }
    };
    /**
     * salelist 를 backend 요청으로 가져온다.
     */
    public getSalesList = async () => {
        // const testCall = await this.kaikasHelper.
        const realRes = await getENSalesList(this.contractAddress.toLowerCase());
        return realRes.data;
    };
}
