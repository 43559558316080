import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import PerPlus from '@/pages/PerPlus';

import Main from '@/pages/main';
// import MyPage from '@/pages/myPage';
import Collections from '@/pages/Collections';
import CollectionsDetail from '@/pages/CollectionsDetail';
import HelpCenter from '@/pages/HelpCenter';
import NotFound from '@/pages/NotFound';
import Registers from '@/pages/Registers';
import { RootState } from './redux/connectors.redux';
import { useDispatch, useSelector } from 'react-redux';
import { useLanguages } from './hooks/useLanguages.hook';
import SearchCollections from '@/pages/SearchCollections';
import KaikasHelper from '@/helpers/Wallet/kaikas.helper';

const Routers: React.FC<any> = (): JSX.Element => {
    const Wallet = useSelector((store: RootState) => store.Wallet);
    const Lang = useLanguages();
    const dispatch = useDispatch();

    useEffect(() => {
        if (Wallet?.type == 'kaikas' && window.klaytn) {
            if (Wallet.info.address !== window.klaytn.selectedAddress) {
                const kaikasHelper = new KaikasHelper(window.klaytn);
                kaikasHelper.login({ wallet: Wallet }, { Lang, dispatch }).catch(console.log);
            }
        }
    }, [window.location.href]);

    return (
        <Switch>
            <Route exact path="/" component={Main} />
            {/* <Route exact path="/myPage" component={MyPage} />
            <Route exact path="/register" component={Registers} />
            <Route exact path="/collections" component={Collections} />
            <Route exact path="/collections/:contractName/:brandName" component={CollectionsDetail} />
            <Route exact path="/helpCenter" component={HelpCenter} /> */}
            {/* <Route exact path="/PerPlus" component={PerPlus} />
            <Route exact path="/PerPlus/:contents/:contractName" component={PerPlus} /> */}
            {/* <Route exact path="/SearchCollections" component={SearchCollections} /> */}
            {/*<Route exact path="/PerPlus/showAttr" component={ShowAttributeRouter} />*/}
            <Route path={'*'} component={NotFound} />
        </Switch>
    );
};

export { Routers };
